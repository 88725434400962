export default [
  //伍全
  // {
  //   id: "Tohatsu Models",
  //   header: "零組件管理",
  //   text: "模型列表",
  //   name: "ManagePage",
  //   parameters: { companyId: "8ef5ecd8-748a-468a-950d-43d09847c1d0" },
  //   icon: "mdi-format-list-bulleted-square",
  //   subMenuItems: [],
  //   meta: {
  //   },
  // },
  // {
  //   id: "Tohatsu Upload",
  //   header: "零組件管理",
  //   text: "模型上傳",
  //   name: "Upload",
  //   parameters: { companyId: "8ef5ecd8-748a-468a-950d-43d09847c1d0" },
  //   icon: "mdi-cloud-upload-outline",
  //   subMenuItems: [],
  //   meta: {
  //   },
  // },
  // {
  //   id: "Tohatsu Upload State",
  //   header: "零組件管理",
  //   text: "模型轉檔管理",
  //   name: "UploadState",
  //   parameters: { companyId: "8ef5ecd8-748a-468a-950d-43d09847c1d0" },
  //   icon: "mdi-cloud-sync-outline",
  //   subMenuItems: [],
  //   meta: {
  //   },
  // }
  // {
  //   id: "Upload Quota Management",
  //   header: "零組件管理",
  //   text: "模型處裡額度管理",
  //   name: "UploadQuotaManage",
  //   parameters: { companyId: "8ef5ecd8-748a-468a-950d-43d09847c1d0" },
  //   icon: "mdi-chart-bar",
  //   subMenuItems: [],
  //   meta: {},
  // },
];
