<template>
  <sb-shared-layout
    :menu-items="menuItems"
    :version="packageJson.version"
    :company-setting-url="companySettingUrl"
    :build-mode-env="buildModeEnv"
    :is-portal="isPortal"
    :logoLink="logoLink"
  ></sb-shared-layout>
</template>

<script>
import packageJson from '@/../package.json';
import localMenuItems from './navDrawerMenuItems';

export default {
  name: 'Main',
  data() {
    let isDev = process.env.VUE_APP_BUILD_MODE === 'development';
    return {
      menuItems: isDev ? localMenuItems : [],
      packageJson: packageJson,
      companySettingUrl: process.env.VUE_APP_COMPANY_SETTINGS,
      buildModeEnv: process.env.VUE_APP_BUILD_MODE,
      isPortal:
        process.env.VUE_APP_REDIRECT_BASE_URL ===
        process.env.VUE_APP_PORTAL_BASE_URL,
      logoLink: process.env.VUE_APP_REDIRECT_BASE_URL,
    };
  },
};
</script>
